import { Kernel } from 'core/kernel'

export class MobileDetect {
  run () {
    if (Kernel.isMobile) {
      document.documentElement.classList.add('device--is-mobile')
    } else {
      document.documentElement.classList.add('device--is-desktop')
    }
  }
}
